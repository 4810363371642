import React, { useState, useEffect } from "react";
import {Autocomplete, Button, TextField, Typography} from '@mui/material';

import womp from '../sound/womp.mp3'
import { styled, lighten, darken } from '@mui/system';

const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: '#111111',
    backgroundColor: '#f9f9f9'
  }));
  
  const GroupItems = styled('ul')({
    padding: 0,
  });

const SongPicker = ({won, setWon, timeLimit, setTimeLimit, correctSong, apiUrl, volume}) => {
    const [songs, setSongs] = useState([]);
    const [selectedSong, setSelectedSong] = useState(null);
    const [guesses, setGuesses] = useState([]);
    
    useEffect(() => {
        fetch(apiUrl + '/song_list')
        .then((response) => response.json())
        .then(response => JSON.parse(response.body)['songs'])
        .then((data) => setSongs(data))
    }, []);
    
    const handleSongChange = (event) => {
        setSelectedSong(event.target.textContent);
    };
    const checkSong = () => {
        if(!selectedSong || !correctSong) return;
        console.log(selectedSong);
        if(selectedSong === correctSong.song_name) {
            setGuesses(guesses.concat({'icon': '✅', 'song': null}))
            setWon(true)
        } else {
            setGuesses(guesses.concat({'icon': '👎', 'song': selectedSong}))
            setTimeLimit(timeLimit + 1)
            if (guesses.length >= 4) {
                const loseSound = new Audio(womp)
                loseSound.volume = volume
                loseSound.play()
            }
        }
    }
    let remainingGuesses = []
    for(let x = 0; x < 5 - guesses.length; x++){
        remainingGuesses.push('❔')
    }
    return (
        <div style={{marginBottom: '10px', width: 'min(93%, 400px)'}}>
            <div style={{display: 'flex', alignItems: 'center', marginBottom: '30px'}}>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={songs}
                    getOptionLabel={(song) => `${song.song_name}`}
                    groupBy={(song) => song.album}
                    onChange={handleSongChange}
                    sx={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Song" />}
                    renderGroup={(params) => (
                        <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                      </li>
                    )}
                />
                <Button variant="contained" color="success" style={{marginLeft: '10px'}} disabled={won || guesses.length >= 5} onClick={checkSong}>
                    Submit
                </Button>
            </div>
            <div style={{ fontSize: '30px' }}>
                {guesses.map((guess, index) => <span key={index}>{guess['icon']}</span>)}
                {remainingGuesses.map((guess, index) => <span key={index}>{guess}</span>)}
                
            </div>
            { guesses.length != 0 ?
                <Typography variant="body1" display="block">
                    Previous guesses:&nbsp;
                    {
                        guesses.map((guess) => guess['song'])
                            .filter(guess => guess != null)
                            .join(', ')
                    }
                </Typography>
            : ''}
            <Typography variant="body1" display="block">
                {guesses.length >= 5 && !won ?
                <span><br />The correct answer was:</span> : ''}
            </Typography>
            {guesses.length >= 5 && !won ? 
                `${correctSong.artist} - ${correctSong.song_name}` : ''}
        </div>
    )
}

export default SongPicker;