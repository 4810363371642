import logo from './logo.svg';
import './App.css';

import Player from './components/Player';
import SongPicker from './components/SongPicker';
import VictoryModal from './components/VictoryModal';

import { useState, useEffect } from 'react';
import { Paper, Typography } from '@mui/material';

function App() {
  const [won, setWon] = useState(false);
  const [volume, setVolume] = useState(0.5)
  const [timeLimit, setTimeLimit] = useState(1);
  const [correctSong, setCorrectSong] = useState(null);
  const apiUrl = process.env.NODE_ENV == 'production' ?
    "https://api.drainedle.com" : "https://api.drainedle.com"
  useEffect(() => {
    fetch(apiUrl + '/song_name')
    .then((response) => response.json())
    .then(response => JSON.parse(response.body))
    .then((data) => setCorrectSong(data));
  }, []);
  return (
    <div className="App" style={{justifyContent: 'space-around', display: 'flex'}}>
      <Paper elevation={3} style={{width: 'min(90%, 800px)', marginTop: '50px', marginBottom: '80px', backgroundColor: '#fafafa'}}>
        <header className="App-header">
          <Typography variant="h2" component="div" gutterBottom>
            Drainedle
          </Typography>
          <SongPicker
            won={won} setWon={setWon}
            timeLimit={timeLimit} setTimeLimit={setTimeLimit}
            correctSong={correctSong} apiUrl={ apiUrl } volume={ volume }
          />
          <Player url={ apiUrl } timeLimit={timeLimit} setTimeLimit={setTimeLimit} volume={volume} setVolume={setVolume}/>
          <VictoryModal won={won} correctSong={correctSong} timeLimit={timeLimit}></VictoryModal>
        </header>
      </Paper>
    </div>
  );
}

export default App;
