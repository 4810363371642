import React, { useState, useEffect } from "react";
import { Slider, Button, Stack, Typography } from "@mui/material";
import { VolumeDown, VolumeUp} from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const useAudio = url => {
  const [audio, setAudio] = useState(new Audio());

  useEffect(() => {
    fetch(url + '/song')
      .then(resp => resp.json())
      .then(resp => {
        audio.src = resp.body;
        setAudio(audio);
      })
  }, []);

  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.volume = 0.3;
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle, audio];
};


const Player = ({ url, timeLimit, setTimeLimit, volume, setVolume }) => {
  const [playing, toggle, audio] = useAudio(url, timeLimit);
  const [playbackProgress, setPlaybackProgress] = useState(0);

  const maxTime = 10;

  const checkPlayback = () => {
    if (audio) {
      if(audio.currentTime > timeLimit && playing) {
        toggle();
        audio.currentTime = 0;
      }
      if(audio.currentTime === 0)
        setPlaybackProgress(0)
      else
        setPlaybackProgress(audio.currentTime / maxTime * 2 + 0.03);
    }
  }
  useEffect(() => {
    const checkInterval = setInterval(checkPlayback, 30);
    return () => clearInterval(checkInterval);
  }, [timeLimit, checkPlayback])

  return (
    <div style={{minWidth: '350px'}}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px'}}>
        <Button onClick={toggle} variant="outlined" style={{marginRight: '10px'}}>{playing ? "Pause" : "Play"}</Button>
        <Stack spacing={1} direction="row" alignItems="center" style={{ minWidth: '150px'}}>
          <VolumeDown />
          <Slider
          size="small"
          defaultValue={30}
          value={parseInt(volume * 100)}
          onChange={(e, value) => {
            setVolume(value / 100);
            if (audio) {
              audio.volume = value / 100;
            }
          }}
          aria-label="Small"
          valueLabelDisplay="auto"
          />
          <VolumeUp />
        </Stack>
      </div>
      <div>
        <Stack spacing={1} direction="row" alignItems="center" style={{ width: '80%', display: 'flex', alignItems: 'center', marginLeft: '30px'}}>
          <Typography variant="overline" component="div" gutterBottom sx={{margin: '3px 8px 0px 0px'}}>0:00</Typography>
          <Slider disabled size='small' defaultValue={1} step={.1} marks min={0} max={1} value={playbackProgress} />
          <Typography variant="overline" component="div" gutterBottom sx={{margin: '3px 8px 0px 0px'}}>0:10</Typography>
        </Stack>
      </div>
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        style={{ position: 'absolute', bottom: '0', left: '0', width: '100%' }}
      >
        Song resets at midnight EST. <br />
        <a href='https://twitter.com/googleforbirds'>twitter</a>
      </Typography>
    </div>
    );
};

export default Player;