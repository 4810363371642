import { Button } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from "react";

import TwitterIcon from '@mui/icons-material/Twitter';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const copyResult = (shareText) => {
    navigator.clipboard.writeText(shareText)
}

const VictoryModal = ({won, correctSong, timeLimit}) => {
    
    const [modalOpen, setModalOpen] = useState(false);
    const handleClose = () => setModalOpen(false);
    useEffect(() => {
        if(won)
            setModalOpen(true)
    }, [won])
    const incorrect = '👎'.repeat(timeLimit - 1)
    const unknown = '❔'.repeat(Math.max(5 - timeLimit, 0))
    const correct = '✅'

    const date = new Date();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    const shareText = `I got the ${month}/${day} #Drainedle in ${timeLimit}!
${incorrect}${correct}${unknown}
https://drainedle.com`
    if(!correctSong)
        return null;
    else
        return (
        <Dialog
            open={modalOpen}
            onClose={handleClose}
            maxWidth="md"  // Set the max width to suit your needs
        >
        <DialogContent style={{width: 'calc(90% - 20px)', maxWidth: '400px', height: '340px'}}>
            <DialogTitle id="alert-dialog-title">
                You got it! 
            </DialogTitle>
        <DialogContentText id="alert-dialog-description" style={{ whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '100%' }}>
        The song was <b>{correctSong.song_name}</b>, from <b>{correctSong.artist} - {correctSong.album}</b> <br /><br />
        <img
            src="https://media.tenor.com/AQpm2UglhNEAAAAd/victim-thaiboy-digital.gif"
            alt="ecco dancing very cute homoerotic"
            style={{ width: '100%', height: 'auto', maxWidth: '350px', marginRight: '10px' }}
        />
        <br />
        </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button
                disableElevation variant="outlined" startIcon={<ContentCopyIcon />} style={{marginRight: '10px'}}
                onClick={() => copyResult(shareText)}>
                Copy to clipboard
            </Button>
            <Button
                href={"https://twitter.com/intent/tweet?text=" + encodeURIComponent(shareText)}
                disableElevation variant="contained" startIcon={<TwitterIcon />}>
                Tweet my score!
            </Button>
        </DialogActions>
        </Dialog>)
}
export default VictoryModal;
